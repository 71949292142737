import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'MainPage',
    component: () => import(  '../views/index/index.vue')
  },
  {
      path: '/exam',
      name: 'exam',
      component: () => import(  '../views/exam/exam.vue')
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import(  '../views/feedback/feedback.vue')
  },
  
  {
      path: '/test',
      name: 'test',
      component: () => import(  '../views/test/test.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(  '../views/about/about.vue')
  },
  {
    path: '/paihang',
    name: 'paihang',
    component: () => import(  '../views/paihang/paihang.vue')
  },
  {
    path: '/prize',
    name: 'prize',
    component: () => import(  '../views/prize/prize.vue')
  },
  {
    path: '/xuanc',
    name: 'xuanc',
    component: () => import(  '../views/xuanc/xuanc.vue')
  },
  {
    path: '/details',
    name: 'details',
    component: () => import(  '../views/details/details.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
